// src/pages/IndexPage.tsx

import React from 'react';
import HomePage from './Home';
import HomePage2 from './Home2';
import JustCompetencies from './JustCompetencies'; // Uncomment if you decide to use it
import About from './About';
import ContactPage from './ContactPage';
// Import any additional components you need

const IndexPage = () => {
  return (
    <div>
      <HomePage2 />
      <HomePage />
      {/* Uncomment the line below if you're including JustCompetencies */}
      {/* <JustCompetencies /> */}
      <About />
      <ContactPage />
      {/* Include any other components that should appear on the index page */}
    </div>
  );
};

export default IndexPage;
