import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicyPage = () => {
  return (
    <Container className="my-5 pt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <h1 className="mb-4 pt-5">Privacy Policy</h1>
          
          <p>Thank you for choosing JUST-hire. We are committed to protecting your privacy. This Privacy Policy describes how JUST-hire ("we," "us," or "our") collects, uses, shares, and protects your personal information when you visit our website or use our services.</p>
          
          <p>By accessing our website or using our services, you consent to the collection, use, and disclosure of your information in accordance with this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our website or services.</p>
          
          <h2>1. Information We Collect</h2>
          <p>We collect personal information that you provide to us directly, such as when you create an account, make a booking, or contact us for support. This may include:</p>
          <ul>
            <li>Name</li>
            <li>Contact information (email address, phone number, address)</li>
            <li>Payment information</li>
            <li>Communications with us (e.g., emails, chats, phone calls)</li>
          </ul>
          <p>We also collect certain information automatically when you use our website or services, including:</p>
          <ul>
            <li>IP address</li>
            <li>Device information (e.g., browser type, operating system)</li>
            <li>Usage data (e.g., pages visited, actions taken)</li>
            <li>Cookies and similar technologies</li>
          </ul>
          
          <h2>2. Use of Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>To provide and improve our services</li>
            <li>To process bookings and payments</li>
            <li>To communicate with you about your account and bookings</li>
            <li>To respond to your inquiries and provide customer support</li>
            <li>To personalize your experience and provide targeted offers</li>
            <li>To detect, prevent, and address technical issues and security vulnerabilities</li>
            <li>To comply with legal obligations</li>
          </ul>
          
          <h2>3. Sharing of Information</h2>
          <p>We may share your personal information with third parties for the following purposes:</p>
          <ul>
            <li>Service providers: We may share your information with third-party service providers who help us operate our business and provide our services.</li>
            <li>Business partners: We may share your information with trusted business partners who offer complementary products or services.</li>
            <li>Legal compliance: We may disclose your information to comply with applicable laws, regulations, legal processes, or government requests.</li>
            <li>Safety and security: We may share your information to protect the safety, rights, or property of JUST-hire, our customers, or others.</li>
          </ul>
          
          <h2>4. Data Security</h2>
          <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.</p>
          
          <h2>5. Your Rights</h2>
          <p>You have certain rights regarding your personal information, including the right to access, correct, or delete your information. You may also have the right to object to or restrict certain types of processing.</p>
          
          <h2>6. Changes to this Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          
          <h2>7. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy, please contact us at contact@JUST-hire.co.</p>
          
          <p>Thank you for trusting JUST-hire with your personal information.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicyPage;
