import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermsOfUsePage = () => {
  return (
    <Container className="my-5 pt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <h1 className="mb-4 mt-5">Terms of Use</h1>
          <p><b>IMPORTANT: PLEASE REVIEW THESE TERMS OF USE BEFORE ACCESSING, USING OR SUBMITTING ANY INFORMATION ON WWW.JUST-HIRE.COM along with all subdomains.</b></p>
          <p>Just-Veritas Limited, and its affiliates (hereinafter “JUST-hire”, “we” or “our”) operate this Website (the “Site”) and we also provide the JUST-hire web-hosted application platform, our mobile application, and provide related and ancillary services (“Services”).</p>
          <p>These Terms of Use set forth the terms and conditions governing access to and use of the Site by casual visitors and potential customers or Users (“you”). Each time you access or use this site, including submitting information requests, you accept the terms and conditions set forth in these Terms of Use as well as the terms and conditions set forth in our Privacy Notice (linked below) (collectively, “Terms”). If you disagree with any part of the Terms, you must not access or use the Site. We reserve the right to modify the Terms from time to time and the revised Terms will apply to use of the Site from the date of the publication of the revised Terms.</p>
          <p>These terms shall not apply to any Customer with whom we have separate terms and/or an end user license agreement that govern Users of our Services that are mutually executed by JUST-hire and Customers (referred to herein collectively as “Users”).</p>
          <p><b>THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT WAIVE YOUR RIGHT TO A COURT HEARING OR JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION. ARBITRATION IS MANDATORY AND IS THE EXCLUSIVE REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW OR IF YOU OPT-OUT.</b></p>
          <h2>1. Acceptable Use</h2>
                    You may use the Site to learn about JUST-hire, our products and services, and to
            request information about our products and services.

            You shall not (and shall not assist any third party to): (a) decompile, disassemble, or
            otherwise reverse engineer or attempt to reconstruct or derive any source code (or
            underlying ideas, algorithms, structure or organisation) from the Site or from any other
            information by any means whatsoever; (b) distribute, disclose or allow use of any of the
            Site in any format through any timesharing device, service bureau, network or by any
            other means, to or by any third party; or (c) modify or create a derivative work of the Site
            or any portion thereof. You further agree that you will not upload or transmit any
            communications or content of any type through the Site that infringe or violate any
            rights of any party. By submitting communications or content through the Site or by
            email to us, you agree that such submission is accurate and non-confidential for all
            purposes. You may not use the Site to send unsolicited commercial email to any person.
            You must not use the Site in any way that causes, or may cause, damage to, or
            impairment of the availability or accessibility of, the Site or in any way which is unlawful,
            illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or

            harmful purpose or activity. You must also not use the Site to copy, store, host, transmit,
            send, use, publish or distribute any material which consists of (or is linked to) any
            spyware, computer virus, Trojan horse, worm, keystroke logger, or other malicious
            computer software.

          <h2 className="mt-4">2. Your Obligations</h2>
                    You are responsible for your usage of the Site, including any information requests you
            may submit through our Site. You shall indemnify us (in this provision – including our
            affiliates, Just-Veritas Limited, and its affiliates (hereinafter “JUST-hire”, “we” or “our”)
            operate this officers, directors, employees, consultants, agents, clients, licensors and
            their respective successors and assigns) and hold us harmless against any claim,
            damage, loss, liability or expense, including reasonable attorneys’ fees, arising out of
            your failure to comply with these Terms or any unauthorised use of the Site. You may
            not, in any way or by any means, rebroadcast or redistribute your access to or use of the
            Site. You may not scrape or collect any information available on the Site or in the
            Services. You may not post or transmit any threatening, obscene, libelous, unlawful or
            otherwise offensive material. In addition, you agree to abide by all applicable local,
            state, national, and international laws and regulations with respect to your use of this
            Site and that you are responsible for determining which such laws apply. We reserve the
            right, in our sole discretion, to immediately and without notice terminate your access to
            and use of this Site.
          
          <h2 className="mt-4">3. License to use the Site; Intellectual Property</h2>
                    Unless otherwise stated, JUST-hire and/or its licensors own the intellectual property
            rights in and to the Site, the Services and all material on and generated from the Site or
            the Services. Except as expressly permitted in these Terms, all intellectual property
            rights in the Site and Services are reserved to JUST-hire. Subject to these Terms, we
            grant you a non-exclusive, non-transferable, limited right to access and use the Site for
            your own personal purposes. No other right, title, or interest in the Site is granted to you.

          <h2 className="mt-4">4. Privacy and Security</h2>
                    By using the Site, and to the extent authorised by applicable law and consistent with our
            Privacy Notice – General, you consent to such Processing of your Personal Data. Our
            Privacy Notice –General can be found here: JUST-hire.com/legal
          
          <h2 className="mt-4">5. Arbitration</h2>
                    PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR
            LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT. SUBJECT TO
            THE TERMS OF THIS SECTION, YOU AND JUST-HIRE AGREE THAT ANY AND ALL
            CLAIMS (AS THAT TERM IS DEFINED BELOW) THAT HAVE ARISEN OR MAY ARISE
            BETWEEN YOU AND JUST-HIRE SHALL BE RESOLVED EXCLUSIVELY THROUGH
            FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS, RATHER THAN IN
            COURT.

            Except as otherwise stated herein, any controversy or claim arising out of or relating to
            these Terms, or the breach thereof, shall be settled by arbitration administered by the
            American Arbitration Association in accordance with its Commercial Arbitration Rules
            and judgment on the award rendered by the arbitrator(s) may be entered in any court
            having jurisdiction pursuant to the Forum Selection provision of these Terms. If you
            desire to opt out of this arbitration provision, you must provide notice to JUST-hire
            before any further use of or access to the Site.
          <h2 className="mt-4">6. Class Action Waiver</h2>
                    Separate and apart from the agreement to arbitrate set forth above and to the
            maximum extent permitted by law, the parties hereby independently waive any right to
            bring or participate in any class action, private attorney general action, or other
            representative action, however denominated, in any way for any Claims against the
            other.
            YOU UNDERSTAND THAT BY AGREEING TO THIS CLASS ACTION WAIVER, YOU MAY
            ONLY BRING CLAIMS AGAINST JUST-HIRE, ITS AGENTS, OFFICERS, SHAREHOLDERS,
            MEMBERS, EMPLOYEES, SUBSIDIARIES, AFFILIATES, PREDECESSORS IN INTEREST,
            SUCCESSORS AND/OR ASSIGNS IN AN INDIVIDUAL CAPACITY AND NOT AS A
            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION, PRIVATE
            ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING.
          <h2 className="mt-4">7. Legal Age Requirement</h2>
                    By accessing or using this Site you hereby represent that you are at least 18 years of
            age – or the legal age of majority in your permanent jurisdiction of residence. We
            reserve the right to request anyone accessing the Site or our Services to provide written
            proof of age in any form.
          <h2 className="mt-4">8. Warranty Disclaimer</h2>
                    THE SITE AND ALL MATERIALS AND SERVICES AVAILABLE ON THE SITE ARE
            PROVIDED BY JUST-HIRE ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE
            FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            SATISFACTORY QUALITY, OR ANY WARRANTY AS TO SECURITY, ACCURACY AND
            NON-INFRINGEMENT, AND ALL OTHER WARRANTIES, WHICH MIGHT OTHERWISE
            ARISE FROM COURSE OF DEALING BETWEEN YOU AND US OR USAGE OF TRADE.
            NEITHER JUST-HIRE, NOR ANY OF OUR CLIENTS, SERVICE PROVIDERS OR
            SUPPLIERS WARRANT THAT THE SITE, THE SERVICES OR ANY FUNCTION
            CONTAINED IN THE SITE OR THE SERVICES WILL BE UNINTERRUPTED OR ERROR-
            FREE, THAT DEFECTS WILL BE CORRECTED OR THAT THE SITE OR SERVICES WILL
            BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NO ADVICE OR
            INFORMATION OBTAINED BY YOU FROM OUR PERSONNEL OR THROUGH THIS SITE

            OR SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY PROVIDED IN
            THESE TERMS.
          <h2 className="mt-4">9. Limitation of Liability</h2>
                    TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER JUST-HIRE, NOR OUR
            CLIENTS, SERVICE PROVIDERS OR SUPPLIERS WILL BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE, OR ANY OTHER
            DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS
            OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF WE
            HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF, OR
            RESULTING FROM ANY USE OR THE INABILITY TO USE THE SITE OR SERVICES
            (INCLUDING CONTENT OR OTHER MATERIAL ON THE SITE OR ANY WEBSITE LINKED
            TO EITHER THE SITE OR THE SERVICES), PERFORMANCE OR USE OF THE SERVICES,
            AS WELL AS UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
            TRANSMISSIONS OR DATA.
            IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES AND LOSSES
            EXCEED THE AMOUNT PAID BY YOU TO US IN THE MONTH IMMEDIATELY
            PRECEDING THE CLAIM OR $100, WHICHEVER IS GREATER. IF YOU ARE
            DISSATISFIED WITH THE SITE, OR WITH ANY OF THESE TERMS, YOUR SOLE AND
            EXCLUSIVE REMEDY IS THE DISCONTINUATION OF YOUR USE OF THE SITE.
           <h2 className="mt-4">10. Breaches of these terms and conditions</h2>
                    Without prejudice to our other rights under these Terms, if you breach these Terms in
            any way, we may take such action as we deem appropriate to deal with the breach,
            including suspending your access to the Site, prohibiting you from accessing the Site,
            blocking computers using your IP address from accessing the Site, and/or bringing
            court proceedings against you. For the avoidance of doubt, any injunctive relief we may
            seek against you for your violation of these Terms shall not be subject to the arbitration
            clause and we may seek such injunctive relief and you waive any requirement of bond
            or proof of damages to seek such injunctive relief.
           <h2 className="mt-4">11. Links</h2>
                    The Site or the Services may contain links to third party websites. JUST-hire is not
            responsible for the content of linked third party websites and does not make any
            representations regarding the content or accuracy of materials on such third-party
            websites.
           <h2 className="mt-4">12. Governing Law and Jurisdiction</h2>
                    These Terms will be governed by, and construed and enforced in accordance with, the
            laws of England and Wales without regard to conflicts of law principles. You hereby
            unconditionally and irrevocably submit to the exclusive jurisdiction and venue of the
            state and/or federal courts located in England over any disputes that are not subject to
            arbitration or actions to enforce any arbitration award (except for disputes brought in
            small claims court).
          <h2 className="mt-4">13. CONTACT US</h2>
          <p style={{ textDecoration: 'underline' }}>contact@JUST-hire.co</p>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfUsePage;
