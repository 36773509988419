import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import NavbarComponent from './components/Navbar';
import Footer from './components/Footer';
import { AppRoutes } from './routes';

function App() {
  return (
    <BrowserRouter>
      {/* Navbar is always displayed */}
      <NavbarComponent />
      
      {/* Route-specific content will be swapped out here */}
      <AppRoutes />
      
      {/* Footer is always displayed */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
