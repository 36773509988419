import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logoImage from '../images/logo-no-bg.png';

const NavbarComponent = () => {
  const navigate = useNavigate();

  // Specify the type of `sectionId` as `string`
  const handleNavLinkClick = (sectionId: string) => {
    // Navigate to home if not already there
    if (window.location.pathname !== '/') {
      navigate('/');
    }

    // Wait for the navigation to complete
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  return (
    <Navbar expand="md" className="fixed-top navbar-gradient" style={{ minHeight: 'var(--navbar-height)' }}>
      <div className="container-fluid">
        <Navbar.Brand id="logo" className="d-flex align-items-center">
          <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <img src={logoImage} alt="Logo" style={{ height: '60px', marginLeft: '20px' }} />
            <h3 style={{ fontWeight: 'normal', marginBottom: 0, color: '#dac794' }}>JUST-hire</h3>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto nav-centered-adjust" style={{ fontSize: '1.4em' }}>
            <Nav.Link style={{ color: '#dac794', marginLeft: "50px" }} onClick={() => handleNavLinkClick('home')}>Home</Nav.Link>
            <Nav.Link style={{ color: '#dac794', marginLeft: "50px" }} onClick={() => handleNavLinkClick('product')}>Product</Nav.Link>
            <Nav.Link style={{ color: '#dac794', marginLeft: "50px" }} onClick={() => handleNavLinkClick('about')}>About Us</Nav.Link>
            <Nav.Link style={{ color: '#dac794', marginLeft: "50px" }} onClick={() => handleNavLinkClick('contact')}>Contact</Nav.Link>
          </Nav>
          <Nav style={{ fontSize: '1.3em' }}>
            <Nav.Link className="login-button" href="https://platform.just-hire.co/" style={{ color: '#dac794' }}>Login</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavbarComponent;
