import { Col, Container, Row } from "react-bootstrap";

import aboutUsImage from "../images/login.jpg";

const LoginPage = () => {
  return (
    <section id="login">
      <img
        src={aboutUsImage}
        alt="about us"
        className="fadded-background-photo-2"
      />
      <Container
        id="login"
        style={{
          color: "var(--clover-border-color)",
        }}
        className="pt-5 pb-5 container-with-image"
      >
        <br />
        <br />
        <br />
        <br />
        <br />

        <Row>
          <Col lg="12">
            <h1 className="text-center">Welcome</h1>
          </Col>
        </Row>

        <Row className="mt-5 lh-3 px-5" style={{ fontSize: "1.3em" }}>
          <Col></Col>
          <Col lg={4} md={6} sm={8}>


            <Row className="mt-5">
              <a
                href="https://platform.justcompetencies.com/"
                className="login-button btn btn-lg btn-secondary"
              >
                Client
              </a>
            </Row>

            <Row className="mt-5">
              <a
                href="https://platform.justcompetencies.com/"
                className="login-button btn btn-lg btn-secondary"
              >
                Candidate
              </a>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </section>
  );
};

export default LoginPage;
