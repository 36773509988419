import { Col, Form, Button, Row, Container} from "react-bootstrap";
import { Link } from 'react-router-dom';
import image from "../images/contact-us.png";
import JustCompentenciesText from "./JustCompentenciesText";
import VideoDemo from "./VideoDemo";
import waving from "../images/photoshopai.png";

const classNames = "mb-3 form-floating";

const Footer = () => {

  return (
    
        <footer className="navbar-gradient text-light pt-4 pb-2">
        <Container>
          <Row className="justify-content-center text-center">
            {/* On small screens, 'justify-content-center text-center' will center the links */}
            <Col xs={12} md="auto">
              <Link to="#" className="d-block d-md-inline mx-2 yellow-color">Cookies</Link>
              <Link to="/privacy" className="d-block d-md-inline mx-2 yellow-color">Privacy Policy</Link>
              <Link to="/terms" className="d-block d-md-inline mx-2 yellow-color">Terms of Use</Link>
              {/* 'd-block' will make each link full width on mobile, and 'd-md-inline' will make them inline on md screens and above */}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col className="text-center yellow-color">
              <p>©️ 2024 <JustCompentenciesText />. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
   
  );
};

export default Footer;
