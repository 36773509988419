// src/routes.tsx

import { Routes, Route } from "react-router-dom";
import ContactPage from "./pages/ContactPage";
import Terms from "./pages/Terms"; 
import Privacy from "./pages/Privacy"; 
import HomePage from "./pages/Home";
import About from "./pages/About";
import JustCompetenciesPage from "./pages/JustCompetencies";
import LoginPage from "./pages/LoginPage";
import IndexPage from "./pages/IndexPage";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/product" element={<JustCompetenciesPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/terms" element={<Terms />} /> 
      <Route path="/privacy" element={<Privacy />} /> 
    </Routes>
  );
};
