import { Row, Col, Container } from "react-bootstrap";
import JustCompentenciesText from "../components/JustCompentenciesText";

import homePageImage from "../images/ai-cover-darken.png";


const Clover = () => {
  return (
    <>
      <h1 className="text-center" style={{ color: "white", fontWeight: "1900", fontSize: "2rem" }}>
        A recruitment software that removes bias with the focus on competencies.        </h1>
      <Col></Col>
      <Col xl={7} lg={8} className="clover-holder mt-4">
        <Row>
          <Col xs={6}>
            <div className="clover one">
              <div className="clover-content">
                <h3>
                  Neutral <br /> Avatar
                </h3>
              </div>
              <div className="hover-content">
              AVI implements a process removing all candidate identifiers for fair competency-based interviews, aiming to boost diversity in hiring.
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className="clover two">
              <div className="clover-content">
                <h3>
                  Competencies <br /> Library
                </h3>
              </div>
              <div className="hover-content">
              Access our extensive library of competency questions at various difficulty levels for your interviews. You can also add your own questions to customize the process.              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className="clover two">
              <div className="clover-content">
                <h3>
                Personality <br /> Preferences
                </h3>
              </div>
              <div className="hover-content">
              Understanding personality preferences in candidates is crucial for hiring because it helps ensure a better fit between the individual and the role, leading to higher job satisfaction, productivity, and team cohesion.               </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className="clover one">
              <div className="clover-content">
                <h3>
                  AI Co-Pilot
                </h3>
              </div>
              <div className="hover-content">
              The science behind using an AI co-pilot for interviews involves objective data analysis, pattern recognition, and machine learning to provide unbiased feedback and minimize human biases.              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </>
  );
};

const HomePage = () => {
  return (
    <section id="product">
      <img src={homePageImage} alt="" className="fadded-background-photo" />
      <Container className="pb-5 pt-5 container-with-image">
        <Row>
          <Clover />
        </Row>
        <Row
          className="mt-2 lh-3 justify-big-screen"
          style={{
            fontSize: "1.3em",
            color: "var(--clover-border-color)",
          }}
        >
          <h1 className="mt-4">
            How <JustCompentenciesText /> eliminates hiring bias
          </h1>
          <p className="mb-4">
          JUST-hire eliminates hiring bias through its innovative virtual reality solution, ensuring a
          fair assessment environment for candidates. By removing demographic identifiers like
          name, gender, and age, as well as other personal details, the focus shifts solely to the
          candidate&#39;s skill set during competency interviews, promoting a level playing field.
          </p>
          <h1 className="mt-4">Avoiding hiring bias is a necessity</h1>
          <p>
          Bias takes less than 6 seconds to form and CVs on average have 160 points of bias.
          Current diversity initiatives look to correct unconscious bias from the assessor, and our
          technology removes this issue entirely. Research confirms that diverse organisations
          financially outperform their non-diverse competitors. Our patent pending technology
          allows for maximum flexibility and efficiency, allowing multiple assessors to
          simultaneously review candidates’ responses. It can be used at any point in your hiring
          process to select the best candidates, creating a fair recruitment process.
          </p>
          <h1 className="mt-4">
            How <JustCompentenciesText /> fits into your hiring process
          </h1>
          <p>
          JUST-hire seamlessly integrates into your hiring process by enabling competency-
          based assessment independent of personal details. This approach ensures a fair and
          unbiased evaluation, with the software offering a diverse library of competency questions customizable to fit your needs. Assessors record scores based on candidate
          responses, ensuring objective evaluation. Candidate identities are revealed only after
          scoring completion, maintaining fairness and transparency.
          </p>
        </Row>
      </Container>
    </section>
  );
};

export default HomePage;
