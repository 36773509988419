import { Row, Col, Card, Container } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Link } from "react-router-dom";
import JustCompentenciesText from "../components/JustCompentenciesText";
import VideoDemo from "../components/VideoDemo";

import image from "../images/contact-us.png";

const JustCompetencies = () => {
  return (
    <section
      id="product"
      className="bg-body pt-5 pb-5"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <Container>
        <Row className="d-flex justify-content-center align-items-center text-center">
          <h1>
            Hello. I’m Avi.
          </h1>
          <Row className="mt-2">
            <Col />
            <Col md="8">
              <img
                src={image}
                alt="Avatar"
                style={{
                  width: "75%",
                  objectFit: "cover",
                  borderRadius: "20%",
                  aspectRatio: "1/1",
                }}
              />
            </Col>
            <Col />
          </Row>
        </Row>
        <h1 className="text-center mt-3">
          <JustCompentenciesText /> in Action
        </h1>
        <br />

        <h1 className="text-center mt-5">Pricing</h1>

        <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
          <Col className="mt-5 ">
            <Card>
              <CardHeader>
                <h4 className="">Trial</h4>
              </CardHeader>
              <div className="card-body">
                <ul className="list-unstyled mt-3 mb-4">
                  <li>1 user included</li>
                  <li>2 GB of storage</li>
                  <li>Email notifications</li>
                  <li>Help center access</li>
                </ul>
                <Link to="/contact">
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-secondary"
                  >
                    Get in touch to learn more
                  </button>
                </Link>
              </div>
            </Card>
          </Col>
          <Col className="mt-5 ">
            <Card>
              <CardHeader>
                <h4 className="">Pro</h4>
              </CardHeader>
              <div className="card-body">
                <ul className="list-unstyled mt-3 mb-4">
                  <li>5 users included</li>
                  <li>20 GB of storage</li>
                  <li>Email notifications</li>
                  <li>Help center access</li>
                </ul>
                <Link to="/contact">
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-secondary"
                  >
                    Get in touch to learn more
                  </button>
                </Link>
              </div>
            </Card>
          </Col>
          <Col className="mt-5 ">
            <Card>
              <CardHeader>
                <h4 className="">Enterprise</h4>
              </CardHeader>
              <div className="card-body">
                <ul className="list-unstyled mt-3 mb-4">
                  <li>10 users included</li>
                  <li>200 GB of storage</li>
                  <li>Email notifications</li>
                  <li>Help center access</li>
                </ul>
                <Link to="/contact">
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-secondary"
                  >
                    Get in touch to learn more
                  </button>
                </Link>
              </div>
            </Card>
          </Col>
        </div>
      </Container>
    </section>
  );
};

export default JustCompetencies;
