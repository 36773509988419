import { Col, Form, Button, Row, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com'; // Import EmailJS library
import image from "../images/contact-us.png";
import JustCompentenciesText from "./JustCompentenciesText";
import VideoDemo from "./VideoDemo";
import waving from "../images/photoshopai.png";

const classNames = "mb-3 form-floating";

const ContactUs = () => {
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) return;
  
    const getValue = (name: string) => {
      const element = form.elements.namedItem(name) as HTMLInputElement | null;
      return element?.value || ''; // Return empty string if element is null
    };
  
    const getValueCheckbox = (name: string) => {
      const element = form.elements.namedItem(name) as HTMLInputElement | null;
      return element?.checked || false; // Return false if element is null
    };
  
    const formData = {
      to_name: 'JUST-hire admin', // Change to the recipient's name or email address
      firstName: getValue("formBasicName"), // Change to match the template variables
      lastName: getValue("formBasicLastName"), // Change to match the template variables
      company: getValue("formBasicCompany"), // Change to match the template variables
      email: getValue("formBasicEmail"), // Change to match the template variables
      howDidYouHear: getValue("formBasicHowDidYouHear"), // Change to match the template variables
      allowEmails: getValueCheckbox("formBasicCheckbox") ? "Yes" : "No",
    };
    
    try {
      // Send email using EmailJS
      await emailjs.send('service_h3csyn2', 'template_k5n9sa4', formData, 'aOfGrXs_7c24y3-KQ');
      alert('Form submitted successfully!');
      // Clear form fields if needed
      form.reset();
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again later.');
    }
  };
  
  return (
    <section id="contact" className="bg-body pt-5 ">
      <Container>
        <Row>
          <Col lg={6}>
            <h2>Interested in learning more, complete the form below to have one of our consultants contact you.</h2>
            <Form
              className="border rounded border border-2 p-3 px-4 mt-3 mb-5"
              onSubmit={handleSubmit}
            >
              <Form.Group className={classNames} controlId="formBasicName">
                <Form.Control type="text" placeholder="Name" name="to_name" required />
                <Form.Label>First Name</Form.Label>
              </Form.Group>

              <Form.Group className={classNames} controlId="formBasicLastName">
                <Form.Control type="text" placeholder="Last name" name="from_name" required />
                <Form.Label>Last Name</Form.Label>
              </Form.Group>

              <Form.Group className={classNames} controlId="formBasicCompany">
                <Form.Control type="text" placeholder="Company" name="company" required />
                <Form.Label>Company</Form.Label>
              </Form.Group>

              <Form.Group className={classNames} controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  name="reply_to"
                  required
                />
                <Form.Label>Email address</Form.Label>
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group
                className={classNames}
                controlId="formBasicHowDidYouHear"
              >
                <Form.Control
                  style={{ height: "80px" }}
                  type="text"
                  placeholder="Company"
                  name="message"
                />
                <Form.Label>How did you hear about us?</Form.Label>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Allow JUST-hire to send me emails"
                  className="text-muted"
                  name="allowEmails"
                />
              </Form.Group>
              <Button variant="secondary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
          <Col
            lg={6}
            className="d-flex justify-content-center justify-content-lg-end"
          >
            <div
              className="flex justify-content-center justify-content-lg-end align-items-center"
              style={{ width: "90%" }}
            >
              <h3 className="mb-4 mt-2 text-center small-heading" style={{ width: "100%" }}>
                Make better hiring decisions with <JustCompentenciesText />.
              </h3>
              <div className="d-flex justify-content-center justify-content-lg-end align-items-center mt-5">
                {/* <VideoDemo /> */}
                <img src={waving} className="waving-robot" alt="waving-robot" />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;
