import { Col, Container, Row } from "react-bootstrap";
import JustCompentenciesText from "../components/JustCompentenciesText";

import aboutUsImage from "../images/dark-about-us.png";

const About = () => {
  return (
    <section id="about" className="about-gradient">
      <img
        src={aboutUsImage}
        alt="about us"
        className="fadded-background-photo"
      />
      <Container
        style={{
          color: "var(--clover-border-color)",
        }}
        className="pt-5 pb-5 container-with-image"
      >
        <Row className="justify-content-center">
          <Col lg="6">
            <h1 className="text-center">About us</h1>
          </Col>
        </Row>

        <Row className="mt-5 lh-3 justify-content-center" style={{ fontSize: "1.6em" }}>
          <Col lg="8">
            <p className="mb-4 text-center">
            At JUST-hire, our journey began with a profound recognition of the systemic challenges
            embedded within recruitment and diversity efforts. Traditional approaches, primarily
            centered on assessor bias training, proved inadequate in addressing the complexities
            of bias ingrained over time. Motivated by the imperative to effect substantial change, we
            embarked on an innovative path.
            Harnessing cutting-edge technology, including an AI co-pilot and personality
            psychometrics, we meticulously crafted a platform designed to dismantle bias at its
            core. By integrating advanced algorithms and virtual reality, we established an
            assessment environment that transcends human limitations, fostering objectivity and
            impartiality.
            </p>
            <p className="mb-4 text-center">
            Our commitment extends beyond bias mitigation. We firmly uphold the principle of
            meritocracy, valuing candidates based on their competencies rather than subjective
            attributes. Thus, we integrated competency-based questioning into our platform,
            ensuring evaluations are rooted in tangible skill sets and capabilities.
            Led by industry experts with extensive experience in HR, Talent Acquisition, and
            customer service, our team is dedicated to revolutionizing legal recruitment practices.
            With a shared ethos of integrity and innovation, we strive to redefine the landscape,
            fostering inclusivity and excellence.
            </p>
            <p className="mb-4 text-center">
            As we embark on this transformative journey, we invite you to join us in shaping the
            future of hiring. Together, let us challenge the status quo, cultivate diversity, and pave
            the way for a more equitable and vibrant workplace.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
