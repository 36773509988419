import React from 'react';
import { Container } from 'react-bootstrap';
import homePageImage from '../images/ai-robot.png'; // Ensure this path is correct

const HomePage2 = () => {
  return (
    <div  style={{ backgroundColor: ''}}>
    <Container id="home" className="pt-5 pb-5 text-center background-animation" style={{ color: '#333',marginTop:"30px",backgroundColor:'' }}> {/* Optional: Adjust background and text color */}
      {/* Text above the image with darker color for visibility */}
      <div className="typewriter pt-5">
        <h2 style={{ color: "#dac794", marginBottom: "20px" }}>Hello World! I’m Avi.</h2>
      </div>

      {/* Adjusted image with rounded edges */}
      <img className="pt-3" src={homePageImage} alt="Contact Us" style={{ maxWidth: '100%', height: 'auto', borderRadius: '20px', width: '60%' }} />
      
      {/* Text below the image with darker color for visibility */}
      <h2 style={{ color: "#dac794", paddingTop: "50px" }}>PRESS<b>PLAY</b></h2>
    </Container>
    </div>
  );
};

export default HomePage2;
